.tablehead {
  background-color: #9e9e9e4d;
}
.totalhours {
  margin-top: -81px;
}
.DateInput_input {
  font-weight: 200;
  font-size: 14px;
  /* line-height: 24px; */
  color: #484848;
  background-color: #fff;
  width: 100%;
  /* padding: 11px 11px 9px; */
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}
.status {
  margin-top: -23px;
}

.cardseting {
  width: 92%;
  margin: 54px;
  margin-top: 27px;
}

.gridcontainer {
  padding: 19px 55px;
}
.textwidth {
  width: 402px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A8A8A8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;

}

.MuiSelect-icon.Mui-disabled{
  display: none;
}

.create-new-vendor .makeStyles-root-26{
  width: 100%!important;
  margin: unset!important;
  border-radius: unset!important;
}

.MuiDataGrid-columnSeparator	{
  display: none!important;
}

.MuiTableCell-body{
  white-space: nowrap!important;
  padding-right: 20px!important;
}

.holidays  .MuiDataGrid-cell{
  min-width: 120px!important;
  text-overflow : unset
}

.holidays .MuiDataGrid-columnHeader{
  min-width: 120px!important;
}

