.textField {
  width: 60%;
}
.formControl {
  margin: "16px";
  min-width : 120;
  
}
.textwidth {
  width: 260px;
  background-color: white;
  
}
.textwidth2 {
  width:100% ! important;;
  background-color: white;
  
}
.emp {
  width: 50px;
}
.App {
  text-align: right;
}
.Createuserbutton{
  color: white;
  background:#53586C;
  border-radius: 42px;
}
.create-new-vendor .MuiDialog-paperWidthSm{
  overflow: visible;
}

.create-new-vendor .MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-center{
  transform: translateY(-20px);
    width: 330px;
    margin: -1px auto;
}

.create-new-vendor .MuiDialogContent-root:first-child{
  padding-top: unset;
}