.filters {
  margin-bottom: -7px;
}
.search {
  margin-top: -4px;
  margin-bottom: -12px;
}
.girdcl {
  margin: 18px 0px 29px;
}
.tableContainers {
  align-items: left;
}
.button {
  margin: "20px";
  background-color: "#4caf50";
  color: #ffffff;
  font-size: 12px;
  border-radius: 15px;
  float: right;
  border-radius: 29;
  padding: 7px 25px;
}
.gridmodel {
  margin: 18px 0px 29px;
}
.tableContainer {
  overflow: inherit;
  margin-top: -18px;
}
