 .textWidth {
   justify-content: center;
    align-items: center;
    text-align: center;
 }
 .button-grid{
     margin-top: 20px;
     margin-bottom: 80px;
}
 #cancel {
     margin: 10px;
      color: #ffffff;
    background-color: #850102;
    font-size: 13px;
    border-radius: 15px; 
    
 }
 #submit {
     margin: 10px;
     background-color: #0b0f0d;
    color: #ffffff;
    font-size: 13px;
    border-radius: 15px;
    }
 .textinput {
       width: 300px;
 }
 .inputSpace {
 padding: 12px 26px;
 }
