body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto','Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.switch-toggler {
  position: relative;
  background: #00b662;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13.5px;
  display: flex;
  justify-content: space-between;
  width: 75px;
  margin-left: 12px;
}

.switch-toggler.inactive {
  background: #ff1744;
  padding-left: 15px;
}

.switch-toggler span {
  width: 9px;
  height: 20px;
  background: #fff;
  position: absolute;
  right: 3px;
  height: 100%;
  top: 0;
  right: 0;
  border: 1px solid #c3c2c2;
  border-radius: 4px;
}

.switch-toggler.inactive span {
  left: 0;
}

.dialog-disable .MuiPaper-elevation.MuiPaper-rounded {
  width: 380px;
  overflow: unset;
}

.dialog-disable-content {
  padding: 1rem;
  padding-top: 0;
  position: relative;
}

.disabled-status-active{
  color: #00b662;
  font-weight: 700;
  text-transform: uppercase;
}

.disabled-status-inactive{
  color: #ff1744;
  font-weight: 700;
  text-transform: uppercase;
}

.alert-wrapper.active .MuiAlert-root{
  width: max-content;
  position: absolute;
  top: 62px;
  right: 0;
}

.alert-wrapper.inactive .MuiAlert-root{
  width: max-content;
  position: absolute;
  top: 62px;
  right: 0;
}

.alert-wrapper.inactive .MuiAlert-message{
  display: flex;
  flex-direction: column;
}

.dialog-disable-icon {
  position: absolute;
  top: 10px;
  right: 30px;
  background: #fff;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
}

.disable-reason-input {
  position: relative;
  margin-bottom: 2rem;
}

.dialog-disable-error {
  position: absolute;
  bottom: -25px;
  color: #ff1744;
  font-size: 14px;
  left: 0;
}
