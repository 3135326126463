.filters {
  align-items: "center";
  margin: "50px";
  
}

.inputWidth {
  width: "80px";
  height: "40px";
}
