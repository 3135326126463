.inputSpace {
 padding: 12px 26px;
 }
 .bgImg {
   min-height: 50vw;
   width: 100%;
 }
.makeStyles-svg-2 {
    width: 1129px;
    height: 604px;
    margin: 11px;
}
@media only screen and (max-width: 600px) {
  .bkImage2 {
    background-color: lightblue;
  }
}