.holidays{
}

.holidays-title{
    font-size: 1.5rem;
}

.holidays-content{
    display: flex;
    padding: 1rem 0 0rem;
}

.holidays-form-container{
    padding: 1rem 10px;
    border-radius: 10px;
    flex: 1;
    box-shadow:  0 0 10px #ddd;
}

.holidays-create{
    margin: 1rem 0;
    outline: unset;
    border: unset;
    padding: 10px 18px;
    font-size: 14px;
    background: #006d77;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
}

.holiday-date-picker{
    width: 100%!important;
}

.holiday-date-picker-container{
    width: 100%;
}

.MuiFormControl-root{
    width: 100%!important;
}
.holiday-description{
    margin: 1rem 0 0;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

.holidays-list{
    flex: 1;
    margin-left: 1rem;
}

.holidays-list-title{
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    padding: 10px;
    margin-bottom: 0;
    color: #fff;
    background: #53586c;
}

.error{
    color: red;
    font-size: 14px;
    margin-bottom: unset;
}